<template>
  <div>
  <CCard>
   
  <CCardBody>
    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
      >
         <template #status-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'status',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Submitted</option>
            <option value="2">Approved</option>
            
          </select>
        </template>
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
<template #status="{item}">
        <td class='font-weight-bold'
          v-if="item.status==1"
        >Submitted</td>
        <td class='font-weight-bold' v-else>Approved</td>
      </template>
     
      <template #firstName="{item}">
        <td class='font-weight-bold'
          v-if="item.firstName"
        >{{ item.firstName}}</td>
        <td v-else>--</td>
      </template>
       <template #lastName="{item}">
        <td class='font-weight-bold'
          v-if="item.lastName"
        >{{ item.lastName}}</td>
        <td v-else>--</td>
      </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
       <!-- <template #targetDate="{item}">
        <td class='font-weight-bold'
          v-if="item.targetDate"
        >{{ item.targetDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template> -->
      <template #dateOfInspection="{item}">
        <td class='font-weight-bold'
          v-if="item.dateOfInspection"
        >{{ item.dateOfInspection |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template>
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                   <CDropdownItem :to="{name: 'Job Task', params: { jobTaskId: item. jobTaskId, mode: 'view'}}">View</CDropdownItem> 

                </CDropdown> 

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
<CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  // { key: "firstName",label: "First Name", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  //  { key: "lastName",label: "Last Name", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "actionToBeTaken",label: "Action To Be Taken", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "conditionDesc",label: "Condition", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "severity",label: "Severity", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "dateOfInspection",label: "Date Of Inspection", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "status",label: "Status", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
  
  { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Job Tasks",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getLocations() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/civil/job/tasks/"+this.$route.params.jobId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
     cancel() {
  
     this.$router.push({ name: "Civil Maintenances" });
    },
  },
  mounted() {
    this.getLocations();
  },
};
</script>